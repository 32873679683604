import HUILITY from '../assets/img/utility/huility.png';
import DEDUST from '../assets/img/utility/dedust.png';
import STONFI from '../assets/img/utility/stonfi.png';
import GECKOTERMINAL from '../assets/img/utility/geckoterminal.png';
import DEWALLET from '../assets/img/utility/dewallet.png';
import SWAP_COFFEE from '../assets/img/utility/swap-coffee.png';
export const utilityMock = [
    {
        title: 'huility',
        img: HUILITY,
        link: 'https://t.me/HuilityBot'
    },
    {
        title: 'DeDust swap',
        img: DEDUST,
        link: 'https://dedust.io/swap/DFC/EQA3BC27XNeinlQFvvQQipMEoTLupUshAkSVb0W129TH_OIf'
    },
    {
        title: 'DeDust pool',
        img: DEDUST,
        link: 'https://dedust.io/pools/EQAkZmvp_rqMSeorfLHlYY_0KZzGIJKnsVXtgGmHLSgfICDu'
    },
    {
        title: 'Stonfi',
        img: STONFI,
        link: 'https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&ft=DFC&tt=EQA3BC27XNeinlQFvvQQipMEoTLupUshAkSVb0W129TH_OIf'
    },
    {
        title: 'Geckoterminal',
        img: GECKOTERMINAL,
        link: 'https://www.geckoterminal.com/ru/ton/pools/EQAkZmvp_rqMSeorfLHlYY_0KZzGIJKnsVXtgGmHLSgfICDu'
    },
    {
        title: 'dewallet',
        img: DEWALLET,
        link: 'https://t.me/dewallet'
    },
    {
        title: 'swap.coffee',
        img: SWAP_COFFEE,
        link: 'https://swap.coffee/dex?ft=DFC&st=PRIVATE'
    }
];
