import DELAB from '../assets/img/partners/delab.png';
import DEFINDER from '../assets/img/partners/definder.png';
import KINGYRU from '../assets/img/partners/kingyru.png';
export const partnersMock = [
    {
        title: 'DeLab',
        img: DELAB,
        link: ' https://t.me/delabteam'
    },
    {
        title: 'DeFinder Capital',
        img: DEFINDER,
        link: 'https://t.me/de_findercapital'
    },
    {
        title: 'Investment kingyru',
        img: KINGYRU,
        link: 'https://t.me/investkingyru'
    }
];
